globalThis["__sentryRewritesTunnelPath__"] = "/monitoring";
globalThis["SENTRY_RELEASE"] = {"id":"0.78.1"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs"
import sentryBuildConfig from "./sentry.config.json"

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  release: sentryBuildConfig.release.name,
  environment: sentryBuildConfig.release.deploy.env,

  // For performance monitoring, capture 25% of the transactions to keep our
  // spend down.
  tracesSampleRate: 0.25,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  replaysOnErrorSampleRate: 1.0,

  // Ignore specific errors
  ignoreErrors: [
    /HierarchyRequestError/,
    /Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node./,
    // Klaviyo sign up form included by Segment that is unused.
    /onsite\/js\/signup_forms/,
  ],

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0,
})
